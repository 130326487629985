:root {
	/* Type */
	--font-family-sans : 'Poppins', sans-serif;
	--font-family-mono : 'IBM Plex Mono', monospace;
	--base-font-size : 22px;
	--base-line-height : 1.75;
	--text-s3 : 0.422rem;
	--text-s2 : 0.563rem;
	--text-s1 : 0.75rem;
	--text-base : 1rem;
	--text-l1 : 1.333em;
	--text-l2 : 1.777em;
	--text-l3 : 2.369em;
	--text-l4 : 3.157em;
	--text-l5 : 4.209em;

	/* Fonts */
	--f-lightest : 200;
	--f-light : 300;
	--f-normal : 400;
	--f-medium : 500;
	--f-bold : 700;
	--f-black : 900;

	/* Colours */
	--white : #ffffff;

    --dino-green : #afcd54;
    --dino-dark-green : #8ca44e;
    --dino-darkest-green : #657738;
    --dino-black : #221f20;
    --dino-yellow : #f4e67e;


    --dark-gray : #353032;
    --light-gray : #f3f3f3;

    --dark-blue : #13293D;
    --mid-blue : #006494;
    --blue: #247BA0;

	
	--ok : #7ADFBB;
	--warning : #FDE74C;
	--danger : #AC3931;
    --white: #ffffff;
    --light-grey: #ccdde4;
    --green: #2A9D8F;
    --yellow: #E9C46A;
    --orange: #F4A261;
    --red: #E76F51;

    /* Mode Colors */
    --primary: var(--dino-black);
    --primary-white: var(--white);
    --body-bg: var(--light-gray);

	/* Default */
	--body-text-color: var(--black-blue);
	--body-bg-color: var(--white);

	/* Width */
	--max-width : 1280px;
	--max-width-content: 720px;
	--gutter: 3rem;

	/* Shadow */
	--shadow-subtle : 0px 0px 7px -4px rgba(0,0,0,0.5);

	/* Break points */
    --tablet-width: 768px;
    --desktop-width: 1024px;
}

html {
    font-family: var(--font-family-sans);
    font-size: var(--base-font-size);
    color: var(--primary);
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--body-bg);
}
#root {
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;
    max-width: 100%;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
a {
    color: inherit;
    font-family: inherit;
}
p.small {
    font-size: 0.75rem;
}
form,
input,
label,
li,
ol,
p,
pre,
select,
ul {font-size: 1rem; }
h5 {font-size: var(--text-base); }
h4 {
    font-size: var(--text-l1); 
    line-height: 1.2;
}
h3 {font-size: 2.37rem; }
h2 {font-size: 3.16rem; }
h1,
h2,
h3,
h4,
h5 {
    margin-top: 0;
    margin-bottom: 0;
}
p {
    line-height: 1.5;
    font-size: var(--text-s1);
}
.App {
    padding-bottom: 0rem;
    overflow-x: hidden;
}

.layout-left nav {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0 var(--gutter) 0;
    text-align: left;
}
nav h1 {
    color: var(--dino-green);
    font-size: var(--text-l2);
    font-weight: var(--f-black);
    text-transform: uppercase;
    letter-spacing: 0.04em;
}
nav h1 small {
    display: block;
    font-size: 0.5em;
    letter-spacing: 0px;
    text-transform: none;
    font-weight: var(--f-light);
    color: var(--primary);
}
footer div p {
    text-align: center;
    color: var(--primary-white);
}
footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    padding: 0.25em 0;
    background-color: var(--dark-gray);
}
.layout-wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    min-height: 100vh;
}
.layout-item > div:not(.layout-right) {
    overflow: auto;
    max-width: calc(var(--max-width) /2);
    padding: 0 var(--gutter) var(--gutter);
}
.layout-item .layout-left {
    margin-left: auto;
    margin-right: 0;
}
.layout-item .layout-right {
    max-width: calc(var(--max-width) /2);
}
.layout-item .layout-right > div > div {
    margin-right: auto;
    margin-left: 0;
    margin-top: 3rem;
    padding-bottom: 0;
    padding: 0 var(--gutter) var(--gutter);
}
.layout-item:last-child {
    background-color: var(--primary-white);
}
.layout-wrapper.two-up .layout-item {
    width: 100%;
    padding: 0;
}


.image-box.paramasaur img {
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
    display: block;
    transform: scaleX(-1);
}
/*
.form-group select {
    background: transparent;
    color: var(--primary);
    font-size: 1rem;
    padding: 10px 10px 10px 5px;
    width: 100%;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid var(--primary);
}*/
.form-group input,
.form-group textarea,
.form-group select {
    font-family: var(--font-family-sans);
    background: transparent;
    color: var(--primary);
    font-size: var(--text-s1);
    font-weight: var(--f-lightest);
    padding: 0.5rem 1.5rem;
    display: block;
    width: 100%;
    border: 2px solid var(--dino-green);
    border-radius: 25px;
}

.form-group select:focus {
    outline: none;
}
.form-group select:focus ~ .bar:before {
    width: calc(100% - 50px);
}
*,
:after,
:before {
    box-sizing: border-box;
}
.form-group {
    position: relative;
}
.form-group textarea {
    resize: none;
}
input:focus,
textarea:focus,
select:focus,
.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
    outline: none;
    border-color: var(--dino-dark-green);
}
.form-group input.hasValues ~ label,
.form-group input:focus ~ label,
.form-group textarea.hasValues ~ label,
.form-group textarea:focus ~ label,
.form-group select.hasValues ~ label,
.form-group select:focus ~ label {
    top: -14px;
    font-size: 12px;
    color: var(--dino-darkest-green);
    font-weight: var(--f-medium);
}
.form-group input:focus ~ .bar:before,
.form-group textarea:focus ~ .bar:before {
    width: calc(100% - 50px);
}
.form-group input[type="password"] {
    letter-spacing: 0.3em;
}
.form-group label {
    color: var(--dino-darkest-green);
    background-color: var(--white);
    font-size: var(--text-s1);
    font-weight: 400;
    position: absolute;
    pointer-events: none;
    left: 25px;
    top: 13px;
    transition: all 0.25s ease;
}
.form-group .bar {
    position: relative;
    display: block;
    width: 100%;
}
.form-group .bar:before {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: var(--dino-yellow);
    transition: all 0.25s ease;
    left: 25px;
}
form {
    text-align: left;
    padding: 0.75rem 0rem 0;
}
.form-group select {
    display: block;
    box-sizing: border-box;
    margin-right: auto;
}
.form-group {
    margin-bottom: 1.33em;
}

.form-group.with-button {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
}
.form-group.with-button button {
    padding: 0.25rem 2rem;
    border: 2px solid var(--dino-green);
    border-radius: 25px 0 0 25px;
    background-color: var(--dino-green);
    color: white;
    font-size: var(--text-base);
    cursor: pointer;
}


ul.edit-params {
    padding-left: 0;
    list-style: none;
}
ul.edit-params li {
    display: flex;
    justify-content: space-between;
    /*border: 2px solid var(--light-gray);*/
    /*border-radius: 25px;*/

    /*padding: 1rem 2rem;*/
    padding: 0 0 1rem;
    text-align: left;
    margin: 0 auto 1rem;
    transition: all 0.25s;
}
/*
ul.edit-params li:hover {
    box-shadow:  var(--shadow-subtle);
} */
ul.edit-params li h5 {
    margin: 0 0 0.5rem;
    font-weight: var(--f-medium);
}
ul.edit-params li h5 small {
    display: block;
    font-size: 0.5em;
    font-weight: var(--f-light);
}
span.close-x {
    float: right;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    border-radius: 100%;
    border: 2px solid var(--dino-green);
    color: var(--dino-green);
    transition: all 150ms ease;
}
span.close-x:hover {
    cursor: pointer;
    color: var(--dino-yellow);
    border-color: var(--dino-yellow);
}
ul.edit-params li.add-state,
ul.edit-params li.edit-state {
    display: block;
}
ul.edit-params li.add-state > div:nth-of-type(2),
ul.edit-params li.edit-state > div:nth-of-type(2) {
    text-align: right;
}
ul.edit-params li.add-state > div:nth-of-type(2) button:first-of-type,
ul.edit-params li.edit-state > div:nth-of-type(2) button:first-of-type {
    margin-right: 1rem;
}
li.edit-params-item {
    transition: all 250ms ease;
    max-height: 150px;
}
li.edit-params-item.edit-state, li.edit-params-item.add-state {
    max-height: 2000px;
}
ul.edit-params li.edit-params-item:last-of-type {
    display: block;
}
ul.edit-params li.view-state > div:first-of-type {
    flex-grow: 2;
    width: 45%;
}
ul.edit-params li.view-state > div:nth-of-type(2) {
    flex-grow: 1;
}
ul.edit-params li.view-state > div:nth-of-type(3) {
    flex-grow: 1;
    text-align: right;
}
ul.edit-params li:last-child {
    border: 0;
    padding-left: 0;
    padding-right: 0;
}
ul.edit-params li:last-child:hover {
    border-width: 0;
    box-shadow: none;
}
.field-edit {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1rem;
}
.field-edit-row {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}
.field-edit-row > :last-child {
    margin-left: 0.5rem;
}
.field-edit .form-group:first-of-type {
    flex-basis: 100%;
}
.field-edit .form-group:nth-of-type(2) {
    flex-grow: 7;
    /*margin-right: 1rem;*/
}
.field-edit .form-group:nth-of-type(3) {
    flex-grow: 1;
}
.badge {
    font-family: var(--font-family-mono);
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: var(--text-s2);
    font-weight: var(--f-light);
    line-height: 1;
    color: var(--primary);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 25px;
    background-color: var(--dino-yellow);
}
.badge a:focus,
.badge a:hover {
    background-color: #a27a18;
    color: #b3b3b3;
    text-decoration: none;
    cursor: pointer;
}
.badge:empty {
    display: none;
}
.form-container {
    margin: 0 auto;
}
.form-container > div.form-header {
    padding: 0.75rem 0rem;
    overflow: auto;
    text-align: left;
}
.form-container > div.form-header h5 {
    margin: 0;
    display: inline-block;
    color: var(--dino-darkest-green);
}
.form-container > div.form-footer {
    padding: 0.75rem 0rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    overflow: auto;
}
.form-output pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    text-align: left;
    min-height: 5rem;
    background-color: var(--light-gray);
    padding: 1rem;
    box-shadow: inset 0 0 10px -5px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    
    font-family: var(--font-family-mono);
    font-size: var(--text-s1);
    margin-bottom: 1rem;

}
.form-output pre.visible {
    visibility: visible;
}

/* Buttons */
.btn {
    border-radius: 25px;
    outline: 0;
    padding: 0.75rem 1rem;
    transition: all 0.25s;
    text-transform: uppercase;
    font-weight: var(--f-light);
    letter-spacing: 0.03rem;
    border-style: solid;
    text-decoration: none;
}
.btn:hover {
    box-shadow: var(--shadow-subtle);
    cursor: pointer;
}
.btn-icon { height: 2.5rem;width: 2.5rem;padding: 0;display: flex;align-items: center;justify-content: center;align-content: center;border: 0;}
.btn-customise {
    background-color: #fff;
    border: 1px solid var(--dark-gray);
    color: var(--dino-black);
    width: 100%;
}
.btn-customise:hover {
    background-color: #e8e8e8;
}
.btn-customise:active {
    box-shadow: none;
    background-color: #d9d9d9;
}
.btn-finish {
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
    width: 100%;
}
.btn-finish:hover {
    background-color: var(--light-gray);
}
.btn-finish:active {
    box-shadow: none;

}
.btn-copy {
    background-color: var(--dino-green);
    border: 0 solid var(--dino-green);
    color: var(--primary);
    width: 100%;
}
.btn-copy:hover {
    background-color: var(--dino-dark-green);
    color: white;
}
.btn-copy:active {
    box-shadow: none;
    background-color: var(--dino-darkest-green);
}
.btn.btn-qr-toggle {
    padding: 0;
    height: 48px;
    min-width: 48px;
    border: 1px solid var(--dino-yellow);
    background-color: var(--dino-yellow);
}
.btn-row {
    display: flex;
}
.btn-row > :not(:first-child) {
    margin-left: 0.5rem;
}
.qr-canvas {
    margin-top: 1.5rem;
}
.qr-canvas canvas {
    margin: 0 auto;
    display: block;
}

button.toggle-view {
    float: right;
}
.btn .badge {
    position: relative;
    top: -1px;
}
.btn-edit {
    background-color: transparent;
    border: 2px solid var(--dino-green);
    color: var(--primary);
    padding: 0.5rem 1rem;
}
.btn-edit:hover {
    background-color: var(--dino-green);
}
.btn-edit:active {
    box-shadow: none;
    background-color: #1a6158;
}
.btn-remove {
    background-color: #e76f51;
    border: 0 solid #e76f51;
    color: #fff;
    padding: 0.5rem 1rem;
}
.btn-remove:hover {
    background-color: #e14e29;
}
.btn-remove:active {
    box-shadow: none;
    background-color: #cf401d;
}
.btn-close {
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
    padding: 0.5rem 1rem;
}
.btn-close:hover {
    background-color: var(--light-gray);
}
.btn-close:active {
    box-shadow: none;
    background-color: #0e1a1f;
}
.btn-add-jumbo {
    background-color: var(--dino-green);

    border: 0 solid var(--dino-green);
    color: #fff;
    outline: 0;
    padding: 0.75rem 1rem;
    width: 100%;
}
.btn-add-jumbo:hover {
    background-color: var(--dino-dark-green);
}
.btn-add-jumbo:active {
    box-shadow: none;
    background-color: var(--dino-dark-green);
}
.qr-canvas .btn-icon {
    background-color: var(--dino-yellow);
}
.qr-canvas .btn-icon:hover {
    background-color: var(--dino-green);
}

.content-wrapper {
    max-width: 100%;
    width: 100%;
    overflow: auto;
}
.content-wrapper.white-panel {
    background-color: var(--primary-white);
    padding: calc(var(--gutter) / 2);
    border-radius: 50px;
    border: 2px solid var(--dino-green);
}

.message-box {
    background-color: var(--dino-yellow);
    padding: calc(var(--gutter) / 4) calc(var(--gutter) / 2);
    margin-top: 1rem;
    border-radius: 50px;
    border: 2px solid var(--dino-yellow);
}
.message-box .text-box :last-child {
    margin-bottom: 0;
}

.image-box > img {
    max-width: 100%;
}

/* Edit item lists */
.list-item-details {
    width: 100%;    
    border: solid 2px var(--dino-green);
    border-radius: 25px;
    padding: 0rem;
    overflow: hidden;
    margin-bottom: 1.25rem;
}
.list-item-details-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    margin: 0.5rem 0.5rem 0.5rem;
}
.list-item-details .list-item-details-row:not(:first-child) .item-group label {
    display: none;
}
.item-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    width: 100%;
}
.list-item-details-row:nth-child(2n) {
    /*background-color: var(--light-gray);*/
}
.list-item-details-row .item-group:first-of-type {
    margin-right: 0.5rem;
}
.list-item-details-row .item-group:nth-child(2n) {
    margin-right: 0.5rem;
}
.item-group label {
    font-size: var(--text-s2);
    color: var(--dino-dark-green);
    width: 100%;
}
.item-group input {
    font-family: var(--font-family-sans);
    color: var(--primary);
    font-size: var(--text-s1);
    font-weight: var(--f-lightest);
    padding: 0.1rem 0.5rem 0.1rem 0;
    border: 0px;
    width: 100%;

    background-color: transparent;
    border-bottom: solid 2px var(--dino-green);
    transition: ease-in-out 250ms;
}
.item-group input:focus {
    border-color: var(--dino-yellow);
}
.item-group-controls {
    display: flex;
    flex-wrap: nowrap;    
    align-content: center;
    align-items: center;
}
button.item-button {
    border: 0px solid transparent;
    border-radius: 0;
    min-width: 30px;

    background-color: var(--dino-yellow);
}
.list-item-details button.item-remove {
    border-radius: 100%;
    padding: 0.33rem;
}
button.item-add {
    width: 100%;
    padding: 0.33rem 1rem;
    border-radius: 25px;

    background-color: var(--dino-green);
}

.list-item-option-group {
    width: 100%;    
    border: 2px solid var(--dino-green);
    border-radius: 25px;
    padding: 0 1rem;
    margin-bottom: 1.33rem;
}
.list-item-option-group .list-item-details {
    border-width: 0px;
    margin-bottom: 0px;
}
.list-item-option-group .list-item-details .list-item-details-row {
    margin-left: 0;
    margin-right: 0;
}
.list-item-details-row.list-item-details-row-footer {
    margin-top: 1rem;
}
.list-item-details-group-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}


.list-item-details-group-header button {
    border: 0px solid transparent;
    border-radius: 100%;
    min-width: 30px;
    min-height: 30px;
    font-size: 1rem;
    color: var(--white);

    background-color: var(--dino-green);
    transition: all 150ms ease;
}
.list-item-details-group-header button.btn-active {
    transform: rotate(45deg);
    background-color: var(--dino-dark-green);
}
.list-item-details {
    transition: 150ms ease-in-out;
    overflow: hidden;
    max-height: 600px;
}
.list-item-details.item-closed {
    max-height: 0px;
    border-color: transparent;
}
.item-group.option {
    position: relative;
}
.item-group.option input {
    font-family: var(--font-family-sans);
    background: transparent;
    color: var(--primary);
    font-size: var(--text-s1);
    font-weight: var(--f-lightest);
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
    display: block;
    width: 100%;
    border: 0px solid var(--dino-green);
    border-radius: 25px;
}
.item-group.option label {
    top: -14px;
    font-size: 12px;
    color: var(--dino-darkest-green);
    font-weight: var(--f-medium);    
    position: absolute;
    pointer-events: none;
    width: auto;
    background-color: white;
}

.list-item-details-row-footer button.item-remove {
    background-color: #e76f51;
    margin-left: 0.5rem;
}
.list-item-details-row-footer.list-item-option-group {
    padding: 0;
    margin-top: -0.5rem;
    display: flex;
}
.material-icons { color: inherit; }
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.flex-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.flex-row .pull-right {
    margin-left: auto;
}
.ml-1 {
    margin-left: 0.25rem;
}
.mr-1 {
    margin-right: 0.25rem;
}

.hover-opacity {
    transition: all 150ms ease;
}
.hover-opacity:hover {
    opacity: 0.7;
    cursor: pointer;
}

/* Side Menu */
.btn.btn-side-menu {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    margin: 1rem 1rem 0 0;
    border: 0;
    background-color: var(--body-bg);
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.btn.btn-side-menu.open {
    background-color: transparent;
    color: var(--dino-green);
}
.btn.btn-side-menu:hover {
    background-color: var(--dino-yellow);
}

.btn.btn-side-menu:active    { 
    background-color: var(--dino-green);
}

nav.side-menu-wrapper {
    position: absolute;
    max-width: 18rem;
    top: 0;
    right: -18rem;
    height: 100%;
    background-color: var(--dark-gray);
    color: var(--white);
    width: 100%;
    box-shadow: 0;
    transition: all 250ms ease;
    z-index: 5;
    padding: calc(var(--gutter) / 2) var(--gutter);
}

nav.side-menu-wrapper.open {
    right: 0;
    box-shadow: -3px 0px 15px -1px rgb(0 0 0 / 50%);
}
nav.side-menu-wrapper > h5 {
    color: var(--dino-green);
    border-bottom: 2px solid var(--dino-dark-green);
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
}

nav.side-menu-wrapper h6 {
    margin-bottom: 0.5rem;
    margin-top: 2rem;
}
nav.side-menu-wrapper ul {
    padding: 0;
    margin: 0;
    list-style: none;

}

nav.side-menu-wrapper li {
    font-size: var(--text-s1);
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0.5rem;
    border-radius: 25px;

    margin-bottom: 0.5rem;
    margin-left: -0.5rem;

    transition: all 150ms ease;

}
.side-menu-wrapper li .material-icons {
    margin-right: 0.5rem;
}
nav.side-menu-wrapper li:hover {
    cursor: pointer;
    background-color: var(--dino-yellow);
    color: var(--dino-black);
}

nav.side-menu-wrapper li:active {
    background-color: var(--dino-green);
    color: var(--dino-black);
}
nav.side-menu-wrapper li .setting-value {
    margin-left: auto;
    font-family: var(--font-family-mono);
    font-size: var(--text-s1);
    border: solid 1px var(--light-gray);
    border-radius: 10px;
    padding: 0.1rem;
    display: block;
    text-align: center;
    min-width: 3.5rem;
    min-height: 1.25rem;
}
nav.side-menu-wrapper li:hover .setting-value,
nav.side-menu-wrapper li:active .setting-value {
    border-color: var(--dino-black);
}

nav.side-menu-wrapper li input {
    display: none;
}
nav.side-menu-wrapper li.field-active input {
    display: block;
    width: 100%;
    max-width: 8rem;
    padding: 0.1rem;
    font-family: var(--font-family-mono);
    font-size: var(--text-s1);
    border: solid 1px var(--light-gray);
    background-color: transparent;
    color: vaR(--white);

    border-radius: 10px;
    margin-left: auto;

    text-align: center;
}
nav.side-menu-wrapper li.field-active span:not(:first-of-type) {
    display: none;
}

nav.side-menu-wrapper li.field-active:hover input, 
nav.side-menu-wrapper li.field-active:active input {
    border-color: var(--dino-black);
    color: var(--dino-black);
}

/* Form Wrapper Animations */
.form-container-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
}

.form-container {
    width: 100%;
    min-width: 100%;
    transition: all 350ms ease;
    overflow: hidden;
}
.form-container-wrapper {
    position: relative;
    /* height: 100%; */
    width: 100%;
}

.form-container {
    position: relative;
    top: 0;
    left: 0;
    z-index: 2;
    /* background-color: white; */
}

.form-container-wrapper.param-view > .form-container:not(.param-view), .form-container-wrapper.edit-view > .form-container:not(.edit-view) {
    left: calc(1 * 60vw);
    position: absolute;
}

/* QR Overlay */
.qr-overlay-wrapper {
    position: fixed;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.qr-canvas {
    background-color: var(--white);
    padding: calc(var(--gutter) / 2);
    border: 5px solid var(--dino-green);
    border-radius: 25px;
    box-shadow: var(--shadow-subtle);
    position: relative;
}

.qr-canvas > button:first-child {position: absolute;top: calc(var(--gutter) / 4);right: calc(var(--gutter) / 4);}

.qr-canvas button:last-child {
    margin: 1.5rem auto 0;
    display: block;
}

.qr-canvas canvas {
    max-width: 100%;
}
.qr-canvas a {
    text-decoration: none;
}
.qr-wrapper {
    max-width: 480px;
    max-height: 480px;
    margin-left: auto;
    margin-right: auto;
}
.qr-wrapper > * {
    max-width: 100%;
    max-height: inherit;
}
.hidden {
    display: none;
}

/* History! */
.history-outer {
    min-height: 100vh;
    width: 100%;
    background-color: var(--dino-black);
    color: var(--white);
    
    overflow: hidden;
    transition: all 350ms ease-in-out;

    position: fixed;
    top: 0;
    z-index: 2;
}
.history-outer.closed {
    max-height: 0px;
    min-height: 0px;
}
.history-outer.open {
    max-height: 100vh;
}
.history-inner {
    margin: 2.5rem 2rem;
}


section h5 {
    color: var(--dino-green);
    border-bottom: 2px solid var(--dino-dark-green);
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
}
section h5 span {
    margin-right: 0.5rem;
}


.history-list {
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 70vh;
    overflow: scroll;
}
.history-list li { 
    display: flex; 
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-block: 0.25rem;
    border-bottom: 1px solid var(--dino-darkest-green);
}
.history-list .btn-row {
    margin-left: auto;
}
.history-date {
    font-size: var(--text-s2);
    display: block;
    color: var(--dino-yellow);
    margin-right: 1rem;
    min-width: max-content;
}
.history-name {
    font-size: var(--text-s1);
    min-width: max-content;
}
.param-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    margin-inline: 1rem;
}
.param-tag {
    font-size: var(--text-s2);
    border: solid 1px var(--dark-gray);
    border-radius: 3px;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    max-height: calc(18.5px + 2 * 5px);
    overflow: hidden;
}
.param-tag-key {
    margin-top: 0;
    margin-bottom: 4px;
    transition: all 150ms ease;
}
.param-tag:hover {
    border-color: var(--dino-yellow);
    color: var(--dino-yellow);
}
.param-tag:hover .param-tag-key {
    margin-top: -23px;
}
.btn.btn-icon-sm {   
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: var(--text-s2);
    padding: 0.25rem 0.5rem;
    border: 1px solid var(--dark-gray);
    background-color: transparent;
    color: white;
}
.btn.btn-icon-sm:hover {
    color: var(--dino-yellow);
    border-color: var(--dino-yellow);
}
.btn.btn-icon-sm.btn-delete:hover {
    color: var(--red);
    border-color: var(--red);
}
.btn.btn-icon-sm.btn-copy:active {
    background-color: var(--dino-green);
    color: var(--dino-black);
    border-color: var(--dino-black);
}

/* Bulk Mode */
.bulk-outer .form-group label {
    font-size: var(--base-font-size);
}
.bulk-outer .form-group input {
    color: white;
}
.bulk-outer .form-group textarea {
    color: currentColor;
    border-radius: 0 25px 25px 0;
    
}
.bulk-outer .field-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    margin-top: 2rem;
}
.bulk-outer .field-list > * {
    flex-basis: calc( (100% - 0.25rem * 4) / 5);
    max-width: 9rem;
}
.bulk-outer .form-group input.hasValues ~ label,
.bulk-outer .form-group label {
    background-color: var(--dino-black);
    color: var(--dino-green);
}
.bulk-outer .form-group label {
    font-size: var(--text-s1);
}

.bulk-outer .bulk-item { font-size: var(--text-s1);  } 
.bulk-item .btn-qr.btn {
    padding: 0.25rem;
} 
.bulk-item .btn-qr > * {
    max-width: 25px;
    height: auto !important;
    aspect-ratio: 1 / 1;
    display: block;
}

@media screen and (max-width: 768px) {
    :root {
        --gutter : 1.5rem;
        --base-font-size : 18px;
    }
    .layout-item > div > div {
        width: 100%;
        max-width: 100%;
    }
    .layout-wrapper > div:first-child {
        width: 30%;
    }
    .layout-wrapper > div:last-child {
        width: 70%;
    }
}
@media screen and (max-width: 480px) {
    :root {
        --gutter : 3rem;
        --base-font-size : 16px;
    }
    .layout-left nav {
        padding-top: 0;
    }
    .layout-item > div.layout-left {
        padding: 1rem calc(var(--gutter) / 2) 3rem;
    }
    .layout-item .layout-right > div > div {
        padding-left: calc(var(--gutter) / 2);
        padding-right: calc(var(--gutter) / 2);
    }
    .form-container-wrapper.param-view > .form-container:not(.param-view), .form-container-wrapper.edit-view > .form-container:not(.edit-view) {
        left: 100vw;
    }
    .layout-wrapper {
        flex-direction: column;
    }
    .layout-wrapper > div:first-child {
        width: 100%;
    }
    .layout-wrapper > div:last-child {
        width: 100%;
    }
    .image-box.paramasaur {
        padding-left: 3rem;
        padding-right: 0rem;
    }
    .param-tags {
        display: none;
    }

}


.fadeIn {
    animation: fadeIn 150ms ease-out 0ms forwards;
}

.fadeOut {
    animation: fadeOut 150ms ease-out 0ms forwards;
}
.version {
    opacity: 0.5;
    font-size: var(--text-s2);
}
/*
 * Animations
 */
 @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  }
